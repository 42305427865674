import React, { Component } from "react";
import { connect } from "react-redux";
import { saveLogin } from "../../redux/actions/loginActions";
import { fetchUser } from "../../redux/actions/userActions";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

import {
  Dashboard,
  Basket,
  User,
  Archive,
  Gift,
  Compliance,
  DocumentText,
  Deliver,
} from "grommet-icons";

class SidebarMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: [],
    };
  }

  render() {
    return (
      <>
        <Navigation
          activeItemId={window.location.pathname}
          onSelect={({ itemId }) => {
            if (itemId.toString().length > 1) {
              this.props.history.push(itemId);
            }
          }}
          items={
            this.props.user.data.role === "SUPER ADMIN"
              ? [
                {
                  title: "Dashboard",
                  itemId: "/dashboard",
                  elemBefore: () => <Dashboard />,
                },
                {
                  title: "Users",
                  itemId: "/users",
                  elemBefore: () => <User />,
                },
                {
                  title: "Customers",
                  itemId: "/customers",
                  elemBefore: () => <User />,
                },
                {
                  title: "Special Order",
                  itemId: "/specialorder",
                  elemBefore: () => <Basket />,
                },
                {
                  title: "Invoices",
                  itemId: "/invoices",
                  elemBefore: () => <DocumentText />,
                },
                {
                  title: "Productions",
                  itemId: "a",
                  elemBefore: () => <Archive />,
                  subNav: [
                    {
                      title: 'All',
                      itemId: '/productions',
                    },
                    {
                      title: 'Hampers & Others',
                      itemId: '/productions?dept=hampers',
                    },
                    {
                      title: 'Kitchen',
                      itemId: '/productions?dept=kitchen',
                    },
                    {
                      title: 'Pastry',
                      itemId: '/productions?dept=pastry',
                    },
                    {
                      title: 'Bakery',
                      itemId: '/productions?dept=bakery',
                    },
                  ],
                },
                {
                  title: "Payment Check (QC)",
                  itemId: "/quality-control",
                  elemBefore: () => <Compliance />,
                },
                {
                  title: "Goods Warehouse",
                  itemId: "/goods-warehouse",
                  elemBefore: () => <Compliance />,
                },
                {
                  title: "Delivery Order",
                  itemId: "/delivery-orders",
                  elemBefore: () => <Deliver />,
                },
                {
                  title: "Pickup Order",
                  itemId: "/delivery-pickup-orders",
                  elemBefore: () => <Deliver />,
                },
                {
                  title: "Items",
                  itemId: "",
                  elemBefore: () => <Archive />,
                  subNav: [
                    {
                      title: 'Products',
                      itemId: '/catalog',
                    },
                    {
                      title: 'Inventory',
                      itemId: '/inventory',
                    },
                    {
                      title: 'Stock In',
                      itemId: '/stock-in',
                    },
                    {
                      title: 'Categories',
                      itemId: '/categories',
                    },
                  ],
                },
              ]
              : this.props.user.data.role === "ADMIN" || this.props.user.data.role !== "SUPER ADMIN"
                ? [
                  {
                    title: "Dashboard",
                    itemId: "/dashboard",
                    elemBefore: () => <Dashboard />,
                  },
                  {
                    title: "Customers",
                    itemId: "/customers",
                    elemBefore: () => <User />,
                  },
                  {
                    title: "Special Order",
                    itemId: "/specialorder",
                    elemBefore: () => <Basket />,
                  },
                  {
                    title: "Invoices",
                    itemId: "/invoices",
                    elemBefore: () => <DocumentText />,
                  },
                  {
                    title: "Productions",
                    itemId: "b",
                    elemBefore: () => <Archive />,
                    subNav: [
                      {
                        title: 'All',
                        itemId: '/productions',
                      },
                      {
                        title: 'Hampers & Others',
                        itemId: '/productions?dept=hampers',
                      },
                      {
                        title: 'Kitchen',
                        itemId: '/productions?dept=kitchen',
                      },
                      {
                        title: 'Pastry',
                        itemId: '/productions?dept=pastry',
                      },
                      {
                        title: 'Bakery',
                        itemId: '/productions?dept=bakery',
                      },
                    ],
                  },
                  {
                    title: "Payment Check (QC)",
                    itemId: "/quality-control",
                    elemBefore: () => <Compliance />,
                  },
                  {
                    title: "Delivery Order",
                    itemId: "/delivery-orders",
                    elemBefore: () => <Deliver />,
                  },
                  {
                    title: "Items",
                    itemId: "",
                    elemBefore: () => <Archive />,
                    subNav: [
                      {
                        title: 'Catalog',
                        itemId: '/catalog',
                      },
                      {
                        title: 'Inventory',
                        itemId: '/inventory',
                      },
                      {
                        title: 'Stock In',
                        itemId: '/stock-in',
                      },
                      {
                        title: 'Categories',
                        itemId: '/categories',
                      },
                    ],
                  },
                ]
                : this.props.user.data.role === "SALES"
                  ? [
                    {
                      title: "Dashboard",
                      itemId: "/dashboard",
                      elemBefore: () => <Dashboard />,
                    },
                    {
                      title: "Special Order",
                      itemId: "/specialorder",
                      elemBefore: () => <Basket />,
                    },
                  ]
                  : this.props.user.data.role === "FINANCE"
                    ? [
                      {
                        title: "Dashboard",
                        itemId: "/dashboard",
                        elemBefore: () => <Dashboard />,
                      },

                      {
                        title: "Invoices",
                        itemId: "/invoices",
                        elemBefore: () => <DocumentText />,
                      },
                    ]
                    : this.props.user.data.role === "WAREHOUSE"
                    ? [
                      {
                        title: "Dashboard",
                        itemId: "/dashboard",
                        elemBefore: () => <Dashboard />,
                      },
                      {
                        title: "Goods Warehouse",
                        itemId: "/goods-warehouse",
                        elemBefore: () => <Compliance />,
                      },
                      {
                        title: "Items",
                        itemId: "",
                        elemBefore: () => <Archive />,
                        subNav: [
                          {
                            title: 'Inventory',
                            itemId: '/inventory',
                          },
                          {
                            title: 'Stock In',
                            itemId: '/stock-in',
                          },
                        ],
                      },
                    ]
                      : this.props.user.data.role === "PRODUCTION"
                        ? [
                          {
                            title: "Dashboard",
                            itemId: "/dashboard",
                            elemBefore: () => <Dashboard />,
                          },
                          {
                            title: "Productions",
                            itemId: "c",
                            elemBefore: () => <Archive />,
                            subNav: [
                              {
                                title: 'All',
                                itemId: '/productions',
                              },
                              {
                                title: 'Hampers & Others',
                                itemId: '/productions?dept=hampers',
                              },
                              {
                                title: 'Kitchen',
                                itemId: '/productions?dept=kitchen',
                              },
                              {
                                title: 'Pastry',
                                itemId: '/productions?dept=pastry',
                              },
                              {
                                title: 'Bakery',
                                itemId: '/productions?dept=bakery',
                              },
                            ],
                          },
                        ]
                        : this.props.user.data.role === "QC"
                          ? [
                            {
                              title: "Dashboard",
                              itemId: "/dashboard",
                              elemBefore: () => <Dashboard />,
                            },
  
                            {
                              title: "Payment Check (QC)",
                              itemId: "/quality-control",
                              elemBefore: () => <Compliance />,
                            },
                          ]
                          : this.props.user.data.role === "DELIVERY" && [
                            {
                              title: "Dashboard",
                              itemId: "/dashboard",
                              elemBefore: () => <Dashboard />,
                            },
                            {
                              title: "Delivery Order",
                              itemId: "/delivery-orders",
                              elemBefore: () => <Deliver />,
                            },
                          ]
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    login: state.loginData,
    user: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveLogin: (id_user, api_token) => {
      dispatch(saveLogin(id_user, api_token));
    },
    fetchUser: (userdata) => {
      dispatch(fetchUser(userdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
